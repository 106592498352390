<template>
	<div>
		<!-- 内容 -->
		<div class="contain flex">
			<div class="page-content">
				<div class="bm-wapper">
					<div class="bm-bar">
						<step-bar :active="0"></step-bar>
					</div>
					<div class="bm-content">
						<title-bar :titles="['招考报名','网上报名']"></title-bar>
						<div class="title">{{info.name}}</div>
						<div class="sub-title">发布时间：{{utils.stringToDate(info.createTime)}}　来源：兰溪人才网</div>
						<div class="content" v-html="decodeURI(info.content)"></div>
						<div class="btn-bm" v-if="info.state==1 || info.state==4 || info.state==99" @click="ts()">{{btntext}}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import StepBar from './stepbar.vue';
	import TitleBar from './titlebar.vue';
	import utils from "@/assets/js/utils";
	import {
		isnull
	} from "@/assets/js/index";
	import {
		GetExamModel,
	} from "@/api/user";
	export default {
		components: {
			StepBar,
			TitleBar
		},
		data() {
			return {
				info: {},
				usertype: 0,
				userinfo: "",
				btntext: "我要报名",
				peR_ID: null,
			};
		},
		created() {
			if (!isnull(localStorage.getItem("userinfo"))) {
				this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
				this.usertype = this.userinfo.userType;
				this.peR_ID = this.userinfo.peR_ID;
			}
			var id = this.$route.query.id;
			if (!isnull(id)) {
				this.Getinfo(id);
			}
		},
		methods: {
			ts() {
				if (this.usertype == 1)
					this.wybm()
				else
					this.$message.error("请先登录个人用户");
			},
			wybm() {
				this.$router.push("zhaokaosumbit?id=" + this.info.id)
			},
			goto(url, data, type = 2) {
				this.resolvewin(url, data, type);
			},
			Getinfo(id) {
				if (isnull(this.peR_ID)) {
					this.$message({
						message: '请先登录，即将跳转到登录页面...', //提示的信息
						type: 'success',
						duration: 3000,
						isshow: true,
						onClose: () => {
							this.goto("login", {
								type: 7
							}, 1);
						}
					});
					return;
				}
				GetExamModel({
					id: id,
					cid: this.peR_ID,
					types:'Enroll'
				}).then((res) => {
					if (res.success) {
						this.info = res.data[0];
						console.log(this.info.state)
						if (this.info.state == 2) {
							this.$message.error("报名未开始！");
							this.usertype = 0;
							return;
						}
						if (this.info.state == 3) {
							this.$message.error("报名已结束！");
							this.usertype = 0;
							return;
						}
						if (this.info.state == 7) {
							this.$message.error("复审未开始，不能重新提交资料！");
							this.usertype = 0;
							return;
						}
						if (this.info.state == 8) {
							this.$message.error("复审已结束，不能重新提交资料！");
							this.usertype = 0;
							return;
						}
						if (this.info.state == 0) {
							this.$message({
								message: '您已经报名了！即将跳转报名记录页面。。。', //提示的信息
								type: 'success',
								duration: 3000,
								isshow: true,
								onClose: () => {
									this.goto("usercenter", {
										type: 7
									}, 1);
								}
							});
							return;
						}
						//  dr["state"] = 90是取消报名，但是不在报名时间范围内的情况，不能报名
						if (this.info.state == 90) {
							this.$message.error("已取消报名，且报名时间已过！");
							this.usertype = 0;
							return;
						}
						//  dr["state"] = 99是可以报名的情况，取消 重新报名，前端需要校验localstorage
						// 如果localstorage里面没有信息，则强制跳转到报名记录界面，记录一次报名信息
						if (this.info.state == 99 && isnull(localStorage.getItem("zhaokaobm_" + this.userinfo.peR_ID))) {
							this.$message({
								message: '您已经报名了！即将跳转报名记录页面。。。', //提示的信息
								type: 'success',
								duration: 3000,
								isshow: true,
								onClose: () => {
									this.goto("usercenter", {
										type: 7
									}, 1);
								}
							});
							return;
						}
						//  dr["state"] = 1是可以报名的情况，驳回重新报名，前端需要校验localstorage
						// 如果localstorage里面没有信息，则强制跳转到报名记录界面，记录一次报名信息
						if (this.info.state == 1 && isnull(localStorage.getItem("zhaokaobm_" + this.userinfo.peR_ID))) {
							this.$message({
								message: '您已经报名了！即将跳转报名记录页面。。。', //提示的信息
								type: 'success',
								duration: 3000,
								isshow: true,
								onClose: () => {
									this.goto("usercenter", {
										type: 7
									}, 1);
								}
							});
							return;
						}
						// dr["state"] = 4是可以报名的情况，从来没有报过这个项目的名，不需要校验localstorage
					} else
						this.$message.error(res.msg);
				});
			},
		},
	};
</script>
<style lang="scss" scoped>
	.contain {
		background-color: #fff;
		min-height: calc(100vh - 125px);
	}
	.page-content {
		width: 1200px;
		margin: 0 auto;
		padding: 20px 0;

		::v-deep .el-tabs__header {
			margin: 0;
		}

		::v-deep .el-tabs__item {
			padding: 0 80px;
		}
	}

	.bm-wapper {
		padding: 20px 0;
		display: flex;
	}


	.title {
		color: #000000;
		font-size: 18px;
		text-align: center;
		margin: 20px 0;
	}
	.bm-bar {
		width: 220px;
		display: flex;
	}

	.bm-content {
		flex: 1;
		padding: 0 48px;
	}

	.sub-title {
		color: rgba(137, 137, 137, 1);
		font-size: 14px;
		text-align: center;
		padding-bottom: 10px;
		border-bottom: 1px solid #eee;
	}

	.content {
		padding: 16px 8px;
		font-size: 14px;
		line-height: 180%;
	}

	.btn-show {
		font-size: 14px;
		font-weight: bold;
	}

	.file-list {
		padding: 0 20px;

		div {
			font-size: 14px;
			color: #1990ff;
		}
	}

	.foot {
		font-size: 14px;
		text-align: right;
	}

	.btn-bm {
		width: 150px;
		margin: 0 auto;
		text-align: center;
		background: #eee;
		border: #ccc;
		height: 40px;
		line-height: 40px;
		margin-top: 80px;
		cursor: pointer;
	}
</style>